import React, { useContext } from "react";
import { gql, useMutation } from "@apollo/client";
import { Link, navigate } from "gatsby";

// Context
import { StoreContext } from "../../../components/context/store";
import { CurrentLanguage } from "../../../components/context/current-language";

const CUSTOMER_LOGOUT = gql`
  mutation customerAccessTokenDelete($customerAccessToken: String!) {
    customerAccessTokenDelete(customerAccessToken: $customerAccessToken) {
      deletedAccessToken
      deletedCustomerAccessTokenId
      userErrors {
        field
        message
      }
    }
  }
`;

const Logout = () => {
  const { setValue, customerAccessToken } = useContext(StoreContext);
  const [currentLanguage, setCurrentLanguage] = useContext(CurrentLanguage);

  const [customerLogout] = useMutation(CUSTOMER_LOGOUT, {
    onCompleted: (data) => {
      if (data.customerAccessTokenDelete.userErrors.length) return;
      setValue({
        customerAccessToken: "",
      });
      window.location.reload();
      navigate(`/${currentLanguage}/account/login/`);
    },
  });

  return (
    <li>
      <button
        type="button"
        onClick={(e) => {
          e.preventDefault();

          customerLogout({
            variables: {
              customerAccessToken: customerAccessToken.accessToken,
            },
          });
        }}
      >
        Logout
      </button>
    </li>
  );
};

export default Logout;
